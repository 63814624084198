import { API_GET_PROJECTNAME, API_GET_OUTPUTID } from '../urls'

const getProjectNames = async (accessToken: string, idToken: string, projectId: string) => {
  const data = {
    projectId: String(projectId),
  }
  const response = await fetch(API_GET_PROJECTNAME, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  } else {
    const result = await response.json()
    return result.result.data
  }
}

const getOutputId = async (projectId: number, idToken: any) => {
  const data = {
    projectId: Number(projectId),
  }
  const response = await fetch(API_GET_OUTPUTID, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(data),
  })
  if (!response?.ok) {
    const message = `An error has occurred: ${response.statusText}`
    throw new Error(message)
  } else {
    const result = await response.json()
    const outputId = result.result.data
    return outputId
  }
}

export { getProjectNames, getOutputId }
