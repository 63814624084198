import React, { useContext } from 'react'
import './index.scss'
import { Modal, Button, Tooltip } from 'antd'
import { DataContext } from '../../DataContext'
import { goToOutputs, checkForMultipleFileValidation } from '../../services/fileUploadService'
import GenerateOutputModal from '../GenerateOutputModal'

interface IGenerateOutputProps {
  checkValidation: () => boolean
  generateOutput: () => void
  projectId: string
  isModalOpen: boolean
}

const GenerateOutputButton: React.FC<IGenerateOutputProps> = (props: IGenerateOutputProps) => {
  const { uploadAllFileList } = useContext(DataContext)
  return (
    <div className='button-section'>
      <Tooltip
        title={
          !checkForMultipleFileValidation(uploadAllFileList)
            ? '.ics file alone cannot be used to generate output'
            : ''
        }
        placement='topLeft'
      >
        <button
          className={
            props.checkValidation() ? 'generate-output-button' : 'disabled-generate-button'
          }
          onClick={() => {
            if (props.checkValidation()) props.generateOutput()
          }}
        >
          Generate Output
        </button>
      </Tooltip>
      <div className='generateOutputModalPopup'>
        <Modal
          className='generateOutputModalPopup'
          style={{ width: '100%' }}
          title=''
          open={props.isModalOpen}
          closable={false}
          footer={[
            <Button
              key='gotoOutput'
              type='primary'
              onClick={() => {
                goToOutputs(props.projectId)
              }}
              className='goTobutton'
            >
              Go To Outputs
            </Button>,
          ]}
        >
          <GenerateOutputModal />
        </Modal>
      </div>
    </div>
  )
}
export default GenerateOutputButton
