import moment from 'moment'
import {
  elaborateText,
  regerateText,
  summarizeText,
} from '../Components/AssistantBotComponent/constants'
import { systemText } from '../Screens/Prepare/constants'
import { API_POST_STREAM } from '../urls'

const postRegenChatMessageWithContext = async (
  chat: any[],
  uuidConst: any,
  recommendedTopic: string,
  recommendedTopicValue: string,
  accessToken: string,
  idToken: string,
  projectId: string,
) => {
  const loading = true
  const requestChatList: { role: string; content: string }[] = []
  requestChatList.push({
    role: 'system',
    content: `${systemText}`,
  })
  for (let i = 0; i < chat.length; i++) {
    if (chat[i].role === 'user') {
      requestChatList.push({
        role: 'user',
        content: chat[i].response,
      })
    } else {
      requestChatList.push({
        role: 'assistant',
        content: chat[i].response,
      })
    }
  }
  requestChatList.push({
    role: 'user',
    content: regerateText,
  })
  const response = await fetch(`/api/chat/regen`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify({
      chat: requestChatList,
      uniq_id: uuidConst,
    }),
  })

  if (response && response.body) {
    const assistantMessage = postToChat(response, loading, recommendedTopic, recommendedTopicValue)
    return assistantMessage
  }
}

const postRegenChatMessageWithoutContext = async (
  lastQuestion: any,
  uuidConst: any,
  recommendedTopic: string,
  recommendedTopicValue: string,
  accessToken: string,
  idToken: string,
  projectId: string,
) => {
  const loading = true
  const requestChatList = []
  requestChatList.push({
    role: 'system',
    content: `${systemText}`,
  })
  requestChatList.push({
    role: 'user',
    content: lastQuestion,
  })
  const response = await fetch(`/api/chat/regen`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify({
      chat: requestChatList,
      uniq_id: uuidConst,
    }),
  })

  if (response && response.body) {
    const assistantMessage = postToChat(response, loading, recommendedTopic, recommendedTopicValue)
    return assistantMessage
  }
}

const postElaborate = async (
  chat: any[],
  uuidConst: any,
  recommendedTopic: string,
  recommendedTopicValue: string,
  accessToken: string,
  idToken: string,
  projectId: string,
) => {
  const loading = true
  const requestChatList: { role: string; content: string }[] = []
  requestChatList.push({
    role: 'system',
    content: `${systemText}`,
  })
  for (let i = 0; i < chat.length; i++) {
    if (chat[i].role === 'user') {
      requestChatList.push({
        role: 'user',
        content: chat[i].response,
      })
    } else {
      requestChatList.push({
        role: 'assistant',
        content: chat[i].response,
      })
    }
  }
  requestChatList.push({
    role: 'user',
    content: elaborateText,
  })
  const response = await fetch(`/api/chat/elaborate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify({
      chat: requestChatList,
      uniq_id: uuidConst,
    }),
  })

  if (response && response.body) {
    const assistantMessage = postToChat(response, loading, recommendedTopic, recommendedTopicValue)
    return assistantMessage
  }
}

const postSummarize = async (
  chat: any[],
  uuidConst: any,
  recommendedTopic: string,
  recommendedTopicValue: string,
  accessToken: string,
  idToken: string,
  projectId: string,
) => {
  const loading = true
  const requestChatList: { role: string; content: string }[] = []
  requestChatList.push({
    role: 'system',
    content: `${systemText}`,
  })
  for (let i = 0; i < chat.length; i++) {
    if (chat[i].role === 'user') {
      requestChatList.push({
        role: 'user',
        content: chat[i].response,
      })
    } else {
      requestChatList.push({
        role: 'assistant',
        content: chat[i].response,
      })
    }
  }
  requestChatList.push({
    role: 'user',
    content: summarizeText,
  })
  const response = await fetch(`/api/chat/summarize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify({
      chat: requestChatList,
      uniq_id: uuidConst,
    }),
  })

  if (response && response.body) {
    const assistantMessage = postToChat(response, loading, recommendedTopic, recommendedTopicValue)
    return assistantMessage
  }
}

const postToChat = async (
  response: Response,
  loading: boolean,
  recommendedTopic: string,
  recommendedTopicValue: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const reader = response.body.getReader()!
  const decoder = new TextDecoder()
  let tempValue = ''
  while (loading) {
    const { value, done } = await reader.read()
    let decodedChunk = decoder.decode(value, { stream: true })
    // Replace '<br> -', '<br>-', '<br>' with '. '
    decodedChunk = decodedChunk.replace(/<br>\s*-?/g, '. ')

    if (value) {
      tempValue += decodedChunk
    }
    if (done) {
      loading = false
      const assistantMessage = {
        role: 'assistant',
        response: tempValue,
        time: moment().format('hh:mm A'),
        recommendedTopic: recommendedTopic,
        topicValue: recommendedTopicValue,
      }
      console.log('tempValue', tempValue, assistantMessage)
      return assistantMessage
    }
  }
}
const postChatMessage = async (
  chat: any[],
  question: any,
  uuidConst: any,
  recommendedTopic: string,
  recommendedTopicValue: string,
  accessToken: string,
  idToken: string,
  projectId: string,
) => {
  const loading = true
  const requestChatList = []
  requestChatList.push({
    role: 'system',
    content: `${systemText}`,
  })
  for (let i = 0; i < chat.length; i++) {
    if (chat[i].role === 'user') {
      requestChatList.push({
        role: 'user',
        content: chat[i].response,
      })
    } else {
      requestChatList.push({
        role: 'assistant',
        content: chat[i].response,
      })
    }
  }
  requestChatList.push({
    role: 'user',
    content: question,
  })
  const response = await fetch(API_POST_STREAM, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
      'Access-Token': accessToken,
      'project-id': projectId,
    },
    body: JSON.stringify({
      chat: requestChatList,
      uniq_id: uuidConst,
    }),
  })

  if (response && response.body) {
    const assistantMessage = postToChat(response, loading, recommendedTopic, recommendedTopicValue)
    return assistantMessage
  }
}

export {
  postElaborate,
  postRegenChatMessageWithContext,
  postRegenChatMessageWithoutContext,
  postSummarize,
  postChatMessage,
}
