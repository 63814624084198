import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import addPageIcon from '../../assets/addPageIcon.svg'
import addPageTrue from '../../assets/addPageTrue.svg'
import './index.scss'

interface IWorkshopAssistantSectionProps {
  createSnippetValue: string
  addPagesCount: number
  uploadingActive: boolean
  setCurrentScreen: (screen: string) => void
  handleAddPage: () => void
}

const WorkshopAssistantSection: React.FC<IWorkshopAssistantSectionProps> = ({
  createSnippetValue,
  addPagesCount,
  uploadingActive,
  setCurrentScreen,
  handleAddPage,
}) => (
  <div className='workshop-assistant-page-container'>
    <div className='workshop-assistant-page-header'>
      Workshop Assistant
      {/* <span className='optional-tag-add-pages'>Optional</span> */}
    </div>
    <div className='workshop-assistant-Box'>
      <div className='workshop-assistant-box-header'>
        Add additional pages by using AI Workshop Assistant. Each AI response can be saved as a
        Snippet of information which you can use in your workshop. You can manage snippets at Block
        Diagram.
      </div>
      <div className='add-pages-box'>
        {createSnippetValue && createSnippetValue.length > 0 ? (
          <>
            <img src={addPageTrue} className='illustrate-icon' alt='Added pages illustration' />
            <div className='no-pages-added-text'>{addPagesCount} additional pages added</div>
            <div className='add-page-button' onClick={() => setCurrentScreen('prepare')}>
              <span className='plus-icon-button' onClick={handleAddPage}>
                <EditOutlined />
              </span>
              Edit Pages
            </div>
          </>
        ) : (
          <>
            <img src={addPageIcon} className='illustrate-icon' alt='No pages illustration' />
            <div className='no-pages-added-text'>There are no added pages</div>
            <Tooltip
              title='Add pages functionality is not allowed until current file upload completes'
              placement='topLeft'
            >
              <div
                className={uploadingActive ? 'disabled-add-page-button' : 'add-page-button'}
                onClick={() => {
                  if (!uploadingActive) {
                    setCurrentScreen('prepare')
                    handleAddPage()
                  }
                }}
              >
                <span className='plus-icon-button'>+</span>
                Add Pages
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  </div>
)

export default WorkshopAssistantSection
