import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import './index.scss'
import {
  emptyStateText,
  regerateText,
  regenerateWithoutContext,
  elaborateText,
  summarizeText,
} from './constants'
import { Input } from 'antd'
import emptyBox from '../../assets/IllustrationEmptyState.svg'
import disableSend from '../../assets/disable-send.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import SnippetModal from '../SnippetModal'
import sendIcon from '../../assets/sendIcon.svg'
import starsIcon from '../../assets/stars.png'
import elaborateIcon from '../../assets/elaborate.png'
import summarizeIcon from '../../assets/summarize.png'
import regenIcon from '../../assets/regen.png'
import { DataContext } from '../../DataContext'

interface IChatNewObj {
  role: string | null
  response: string
  time: string
}
interface IAssistantBotComponent {
  question: string
  setQuestion: (value: string) => void
  roleName: string | null
  chat: IChatNewObj[]
  loading: boolean
  sendChat: () => void
  sendRegenChatWithContext: () => void
  sendRegenChatWithoutContext: () => void
  sendElaborate: () => void
  sendSummarize: () => void
  currentActiveTab: string
  handleModalOk: () => void
  showModal: (index: number) => void
  setParentList: Dispatch<SetStateAction<{ label: string; value: number }[]>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setInputValue: Dispatch<SetStateAction<{ name: string; parentId: number }>>
  inputValue: { name: string; parentId: number }
  showModalLoader: boolean
  isModalOpen: boolean
  selectKey: number
  defaultLabel: string
  parentList: { label: string; value: number }[]
  error: string
  setCurrentActiveIndex: Dispatch<SetStateAction<{ value: number }>>
}

const AssistantBotComponent: React.FC<IAssistantBotComponent> = (props: IAssistantBotComponent) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { chatActiveIndex } = useContext(DataContext)
  const { TextArea } = Input

  const renderChatbot = () => {
    if (props.chat.length > 0) {
      return (
        <>
          <div className='chatBotState'>
            <div ref={ref}>
              {props.chat.map((chatObj: IChatObj, index: number) => (
                <div key={index}>{checkUserOrAssistant(chatObj, index)}</div>
              ))}
              {props.loading && (
                <div style={{ marginTop: '16px' }}>
                  <div className='assistantSection'>
                    <div className='assitantChat'>AI</div>
                    <div className='assistantText'>Assistant</div>
                  </div>
                  <div className='assitantResponseLoader'>
                    <div className='loader' />
                    <span className='loadText'>Working on your request</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='emptySectionState'>
            <img src={emptyBox} />
            <div className='noRequestTopHeading'>There are no requests</div>
            <div className='noRequestBottomHeading'>{emptyStateText}</div>
          </div>
        </>
      )
    }
  }

  const checkUserOrAssistant = (chatObj: IChatObj, index: number) => {
    if (
      chatObj?.role === 'user' &&
      chatObj?.response !== regerateText &&
      chatObj.response !== regenerateWithoutContext &&
      chatObj.response !== elaborateText &&
      chatObj.response !== summarizeText
    ) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='userChat'>{chatObj?.response}</div>
          <span style={{ margin: '10px 27px 0px auto' }}>{chatObj?.time}</span>
        </div>
      )
    }
    if (
      chatObj?.role === 'assistant' &&
      chatObj?.response !== regerateText &&
      chatObj.response !== regenerateWithoutContext &&
      chatObj.response !== elaborateText &&
      chatObj.response !== summarizeText
    ) {
      return (
        <>
          {chatObj.response && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '125vh' }}>
              <div style={{ marginTop: '16px' }}>
                <div className='assistantSection'>
                  <div className='assitantChat'>AI</div>
                  <div className='assistantText'>Assistant</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className='assitantResponse' style={{ marginRight: '28px' }}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{chatObj.response}</ReactMarkdown>
                  </div>

                  <SnippetModal
                    parentList={props.parentList}
                    disabled={props.roleName ? false : true}
                    text={chatObj?.response}
                    roleName={props.roleName}
                    handleModalOk={props.handleModalOk}
                    currentActiveIndex={index}
                    showModal={props.showModal}
                    setParentList={props.setParentList}
                    setIsModalOpen={props.setIsModalOpen}
                    setInputValue={props.setInputValue}
                    inputValue={props.inputValue}
                    showModalLoader={props.showModalLoader}
                    isModalOpen={props.isModalOpen}
                    selectKey={props.selectKey}
                    defaultLabel={props.defaultLabel}
                    error={props.error}
                    setCurrent={props.setCurrentActiveIndex}
                  />
                </div>
              </div>
              <span style={{ margin: '10px 70px 0px auto' }}>{chatObj?.time}</span>
            </div>
          )}
        </>
      )
    }
  }

  const handleChange = (value: string) => {
    props.setQuestion(value)
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  }, [props?.chat])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        className='assistantBot'
        style={{ height: '85%', paddingRight: '16px', marginRight: '10px' }}
      >
        {renderChatbot()}
        {props.chat.length > 0 ? (
          <div className='quick-actions-container'>
            <div className='quick-actions-card' onClick={props.sendRegenChatWithContext}>
              <img src={regenIcon} />
              Regenerate
            </div>
            <div className='quick-actions-card' onClick={props.sendRegenChatWithoutContext}>
              <img src={regenIcon} />
              Regenerate Without Context
            </div>
            <div className='quick-actions-card' onClick={props.sendElaborate}>
              <img src={elaborateIcon} />
              Elaborate
            </div>
            <div className='quick-actions-card' onClick={props.sendSummarize}>
              <img src={summarizeIcon} />
              Summarize
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='chatbotFooter'>
        <div className='requestType'>
          <TextArea
            variant='borderless'
            value={props.question}
            onChange={(e) => handleChange(e.target.value)}
            placeholder='Type your request or question'
            autoSize={{ maxRows: 3 }}
          />
        </div>
        <div className='sendButton'>
          {!props.question && !props.loading ? (
            <img className='sendButtonImg' src={disableSend} />
          ) : (
            <button className='sendButtonEnabled' onClick={props.sendChat}>
              <img src={sendIcon} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssistantBotComponent
