// import { Mermaid, IMermaidProps } from './Mermaid'
// import { LoadingOutlined } from '@ant-design/icons'
// import { Spin } from 'antd'
import { useEffect, useState } from 'react'
import getToken from '../../addTokenInterceptor'
import './index.scss'
import { API_GET_PROJECTNAME } from '../../urls'
import logo from '../../assets/Logo.svg'
import bg from '../../assets/background2.png'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import Prepare from '../../Screens/Prepare'
import KDDLanding from '../../Screens/KDDLanding'

const UserAuthorization: React.FC = () => {
  const { instance } = useMsal()
  const { accessToken, idToken, projectId, user_email } = getToken()
  const [userEmail, setUserEmail] = useState('')
  const [projectNames, setProjectNames] = useState<string | null>(null)

  useEffect(() => {
    if (projectId && user_email) {
      setUserEmail(user_email)
      const fetchProjectNames = async () => {
        try {
          const names = await getProjectNames()
          setProjectNames(names)
        } catch (err) {
          console.error(err)
        }
      }
      fetchProjectNames()
    }
  }, [projectId, user_email])

  const logout = async () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const getProjectNames = async () => {
    const data = {
      projectId: `${projectId}`,
    }
    const response = await fetch(API_GET_PROJECTNAME, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        'Access-Token': accessToken,
        'project-id': projectId,
      },
      body: JSON.stringify(data),
    })
    if (!response?.ok) {
      const message = `An error has occurred: ${response.statusText}`
      setProjectNames('hahaha')
      throw new Error(message)
    } else {
      const result = await response.json()
      return result.result.data
    }
  }
  const downloadDocumentation = (): string =>
    'https://amedeloitte.sharepoint.com/:w:/r/sites/GenerativeAI-Oracle/_layouts/15/download.aspx?SourceURL=Shared%20Documents/04.%20Training/Ascend%202.0/Ascend%20GenAI%20User%20Manual.pdf'

  return (
    <>
      {projectNames == '' || projectNames == null || projectNames == 'hahaha' ? (
        <div>
          <div style={{ width: '100%', zIndex: '999' }}>
            <div className='header'>
              <div className='header-left'>
                <img src={logo} alt='deloitte logo' className='header-logo' />
                <div className='vertical-line' />
                <span className='header-text'>
                  Gen AI<sup>TM</sup>
                </span>
              </div>
              <div className='header-right'>
                <div className='float-right'>
                  <div className='email-text'>
                    <a href={downloadDocumentation()} className='content download-user-guide'>
                      <DownloadOutlined className='download-icon' /> Download User Guide
                    </a>
                  </div>
                  <div className='email-text'>
                    <div className='content'>{userEmail}</div>
                  </div>
                  <Button className='sign-button' type='primary' onClick={() => logout()}>
                    Sign Out
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='parent'>
            <img className='image1' src={bg} />
            {/* <img className='image2' src='../../assets/image.png' /> */}
          </div>
          {/* <br /> */}
          {projectNames == '' || projectNames == 'hahaha' ? (
            <h2>
              You seem to access wrong URL, please verify that you are accessing the correct URL.
            </h2>
          ) : (
            <h2>Please wait... We are checking your access authorization!!</h2>
          )}
        </div>
      ) : (
        <KDDLanding />
      )}
    </>
  )
}

export default UserAuthorization
