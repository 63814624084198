import { useContext, useEffect, useState } from 'react'
import './index.scss'
import emptySelect from '../../assets/not-found.svg'
import { saveAs } from 'file-saver'
// import { saveAs } from 'file-saver'
import { Button, Checkbox, Divider, Modal, Tooltip } from 'antd'
import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import getToken from '../../addTokenInterceptor'
import { DataContext } from '../../DataContext'

interface IAddFilesCallback {
  promptvalue: string
  projectid: string
  idtoken: string
  accesstoken: string
  addfilescallback: (files) => void
  kbFiles: any
  user_email: string
}

interface IKnbObj {
  file_name: string
  size: number
  links: {
    preview_url: string
    download_url: string
  }
}
const KnowledgeBaseImport: React.FC<IAddFilesCallback> = (props: IAddFilesCallback) => {
  const [plainOptions, setPlainOptions] = useState([])
  const { workshopNameData, promptData, setCurrentScreen, uuidConst } = useContext(DataContext)
  const API_URL = import.meta.env.VITE_KDD_API

  const { projectId } = getToken()
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [tempCheckedList, setTempCheckedList] = useState<string[]>([])
  let newCheckedList: string[] | ((prevState: string[]) => string[]) = []

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { sections } = useContext(DataContext)

  const showLoading = async () => {
    setOpen(true)
    setLoading(true)
    try {
      const data = {
        textprompt: promptData,
        uniq_id: uuidConst,
        templateFile: null,
        outputfiles: [{ outputtype: 'Knowledge Base' }],
        projectId,
        sections,
        workshopName: workshopNameData,
      }
      const response = await fetch(`${API_URL}/WorkshopAst/process_request/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${props.idtoken}`,
          'Access-Token': props.accesstoken,
          'project-id': projectId,
        },
        body: JSON.stringify(data),
      })
      if (!response?.ok) {
        const message = `An error has occurred: ${response.statusText}`
        // setPlainOptions([])
        setLoading(false)
        throw new Error(message)
      } else {
        const result = await response.json()
        setPlainOptions(result)
        setLoading(false)
      }
      // const res = [
      //   {
      //     file_name: 'O2_GENAI_WD191_Seagen Supply Chain Management Deployment Plan-01312023.pptx',
      //     size: 9293588,
      //     links: {
      //       preview_url:
      //         'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdascendgenaieus2stor.blob.core.windows.net%2Fascendgenaiworkshopast%2FO2_GENAI_WD191_Seagen%2520Supply%2520Chain%2520Management%2520Deployment%2520Plan-01312023.pptx%3Fse%3D2024-08-11T04%253A30%253A11Z%26sp%3Dr%26sv%3D2023-08-03%26sr%3Db%26sig%3D%2F8vFCD3JPAStbLvif937XbRByd4KcWgZIEXNyouGfC4%253D',
      //       download_url:
      //         'https://dascendgenaieus2stor.blob.core.windows.net/ascendgenaiworkshopast/O2_GENAI_WD191_Seagen%20Supply%20Chain%20Management%20Deployment%20Plan-01312023.pptx?se=2024-08-11T04%3A30%3A11Z&sp=r&sv=2023-08-03&sr=b&sig=/8vFCD3JPAStbLvif937XbRByd4KcWgZIEXNyouGfC4%3D',
      //     },
      //   },
      //   {
      //     file_name: 'O2_GENAI_WD253_Financial Sub Ledger.pptx',
      //     size: 2359511,
      //     links: {
      //       preview_url:
      //         'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdascendgenaieus2stor.blob.core.windows.net%2Fascendgenaiworkshopast%2FO2_GENAI_WD253_Financial%2520Sub%2520Ledger.pptx%3Fse%3D2024-08-11T04%253A30%253A11Z%26sp%3Dr%26sv%3D2023-08-03%26sr%3Db%26sig%3D6achyfITpr8cQ%2FZNZfzZL%252BfWKCu5LGtpdxgWzJqaGUc%253D',
      //       download_url:
      //         'https://dascendgenaieus2stor.blob.core.windows.net/ascendgenaiworkshopast/O2_GENAI_WD253_Financial%20Sub%20Ledger.pptx?se=2024-08-11T04%3A30%3A11Z&sp=r&sv=2023-08-03&sr=b&sig=6achyfITpr8cQ/ZNZfzZL%2BfWKCu5LGtpdxgWzJqaGUc%3D',
      //     },
      //   },
      //   {
      //     file_name: 'O2_GENAI_WD110_Project_Notorious_Cash Management_vF.pptx',
      //     size: 3529519,
      //     links: {
      //       preview_url:
      //         'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdascendgenaieus2stor.blob.core.windows.net%2Fascendgenaiworkshopast%2FO2_GENAI_WD110_Project_Notorious_Cash%2520Management_vF.pptx%3Fse%3D2024-08-11T04%253A30%253A11Z%26sp%3Dr%26sv%3D2023-08-03%26sr%3Db%26sig%3DGKo%252ByZidHhsShqeVc1bRjAvGIZ7gDiRROs5jy2pomVg%253D',
      //       download_url:
      //         'https://dascendgenaieus2stor.blob.core.windows.net/ascendgenaiworkshopast/O2_GENAI_WD110_Project_Notorious_Cash%20Management_vF.pptx?se=2024-08-11T04%3A30%3A11Z&sp=r&sv=2023-08-03&sr=b&sig=GKo%2ByZidHhsShqeVc1bRjAvGIZ7gDiRROs5jy2pomVg%3D',
      //     },
      //   },
      //   {
      //     file_name: 'O2_GENAI_WD252_General_ledger_Conversion.pptx',
      //     size: 1515310,
      //     links: {
      //       preview_url:
      //         'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdascendgenaieus2stor.blob.core.windows.net%2Fascendgenaiworkshopast%2FO2_GENAI_WD252_General_ledger_Conversion.pptx%3Fse%3D2024-08-11T04%253A30%253A12Z%26sp%3Dr%26sv%3D2023-08-03%26sr%3Db%26sig%3D3HaHsst2yN7ugk9CBEYo5jNVbry%252BR2ohOO5lO4rTIlI%253D',
      //       download_url:
      //         'https://dascendgenaieus2stor.blob.core.windows.net/ascendgenaiworkshopast/O2_GENAI_WD252_General_ledger_Conversion.pptx?se=2024-08-11T04%3A30%3A12Z&sp=r&sv=2023-08-03&sr=b&sig=3HaHsst2yN7ugk9CBEYo5jNVbry%2BR2ohOO5lO4rTIlI%3D',
      //     },
      //   },
      //   {
      //     file_name: 'O2_GENAI_WD296 Customer Master Data Maintenance.pptx',
      //     size: 80784,
      //     links: {
      //       preview_url:
      //         'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fdascendgenaieus2stor.blob.core.windows.net%2Fascendgenaiworkshopast%2FO2_GENAI_WD296%2520Customer%2520Master%2520Data%2520Maintenance.pptx%3Fse%3D2024-08-11T04%253A30%253A12Z%26sp%3Dr%26sv%3D2023-08-03%26sr%3Db%26sig%3Db%252Bwoo%252BiuchNMZSjUMBRF1dbrHlo4uUSOpMWz66ZQe%2Fc%253D',
      //       download_url:
      //         'https://dascendgenaieus2stor.blob.core.windows.net/ascendgenaiworkshopast/O2_GENAI_WD296%20Customer%20Master%20Data%20Maintenance.pptx?se=2024-08-11T04%3A30%3A12Z&sp=r&sv=2023-08-03&sr=b&sig=b%2Bwoo%2BiuchNMZSjUMBRF1dbrHlo4uUSOpMWz66ZQe/c%3D',
      //     },
      //   },
      // ]
      // setPlainOptions(res)
      // setLoading(false)
    } catch (err) {
      console.log('ERR', err.message)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    setTempCheckedList(props.kbFiles)
  }, [props.kbFiles])
  const handleCancel = () => {
    setTempCheckedList(checkedList)
    setOpen(false)
  }
  const handleRephrase = () => {
    setTempCheckedList(checkedList)
    setOpen(false)
    setCurrentScreen('WorkshopDetails')
  }

  const handleModalOk = () => {
    newCheckedList = tempCheckedList
    props.addfilescallback(newCheckedList)
    setCheckedList(tempCheckedList)
    setOpen(false)
  }

  const handleDownload = (option: string, fileName: string) => {
    saveAs(option, `${fileName}.pptx`)
  }
  const handlePreview = (option: string) => {
    window.open(option, '_blank')
  }

  const handleCheckboxChange = (e, option: string) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setTempCheckedList([...tempCheckedList, option])
    } else {
      setTempCheckedList(tempCheckedList.filter((item) => item !== option))
    }
  }

  return (
    <>
      <div className='whole-modal'>
        <Button onClick={showLoading} className='kb-button-inside'>
          Open Knowledge Base
        </Button>
        <Modal
          className='knb-modal'
          title={<h2>Select Documents</h2>}
          footer={[
            <Button key='cancel' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key='done'
              className={'done-button'}
              type='primary'
              onClick={() => {
                if (plainOptions.length > 0) handleModalOk()
                else handleRephrase()
              }}
            >
              {plainOptions.length > 0 ? 'Done' : 'Rephrase prompt'}
            </Button>,
          ]}
          maskClosable={false}
          loading={loading}
          open={open}
          onCancel={() => setOpen(false)}
        >
          {plainOptions.length ? (
            <div className='total-container'>
              <div className='info-container'>
                <span className='info-span'>
                  Below are the top 5 Workshop Decks from the Deloitte Knowledge Base. Please select
                  one to three files as references for generating the output.
                </span>
              </div>
              <Divider />
              <div className='checkbox-group'>
                {plainOptions.map((name: IKnbObj) => {
                  const checkboxId = `checkbox-${name.file_name}`
                  return (
                    <div
                      key={name.file_name}
                      className='single-checkbox-div'
                      onChange={(e) => handleCheckboxChange(e, name.file_name)}
                    >
                      <label
                        htmlFor={checkboxId}
                        className='single-checkbox-kdd'
                        // onClick={(e) => handleCheckboxChange(e, name.file_name)}
                      >
                        <div className='check-span-container'>
                          <Checkbox
                            className='single-input custom-checkbox'
                            checked={tempCheckedList.includes(name.file_name)}
                            onChange={(e) => handleCheckboxChange(e, name.file_name)}
                            id={checkboxId}
                            disabled={
                              tempCheckedList.length === 3 &&
                              !tempCheckedList.includes(name.file_name)
                            }
                            name='section'
                            value={name}
                          />
                          <div className='span-edit-del-container-knb'>
                            <div className='name-title-container'>
                              <Tooltip
                                title={name.file_name.substring(0, name.file_name.length - 5)}
                                placement='topLeft'
                              >
                                <span className='single-label'>
                                  {name.file_name.length <= 45 ? (
                                    <>{name.file_name.substring(0, 45)}</>
                                  ) : (
                                    <>{name.file_name.substring(0, 50)}...</>
                                  )}
                                </span>
                                <span className='subheading-span'>
                                  {name.file_name.substring(15, name.file_name.length - 5).length <=
                                  45 ? (
                                    <>
                                      <span className='title-span'>Title: </span>
                                      {name.file_name.substring(15, name.file_name.length - 5)}
                                    </>
                                  ) : (
                                    <>
                                      {name.file_name.length - 15 > 75 ? (
                                        <>
                                          <span className='title-span'>Title: </span>
                                          {name.file_name.substring(15, 65)}...
                                        </>
                                      ) : (
                                        <>
                                          <span className='title-span'>Title: </span>
                                          {name.file_name.substring(15, 65)}...
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>

                        <div className='buttons-container'>
                          <Button
                            className='unselect-button'
                            onClick={() => handleDownload(name.links.download_url, name.file_name)}
                          >
                            <DownloadOutlined />
                            Download
                          </Button>
                          <Button onClick={() => handlePreview(name.links.preview_url)}>
                            <ExportOutlined />
                            Preview
                          </Button>
                        </div>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <div className='empty-selected'>
              <div className='empty-select'>
                <img src={emptySelect} />
              </div>
              <div className='no-match-text'>No documents found</div>
              <div className='no-documents-text'>
                No matching documents found in the Deloitte Knowledge Base. Rephrase your prompt for
                better results.
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  )
}

export default KnowledgeBaseImport
