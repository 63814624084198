import { useContext, useState } from 'react'
import './index.scss'
import { Button, message, Progress, Typography, Upload, UploadProps } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import check from '../../assets/checkIcon.svg'
// import getToken from '../../addTokenInterceptor'
import { DataContext } from '../../DataContext'
import divider from '../../assets/divider.svg'
import info from '../../assets/Description.svg'
import cross from '../../assets/cross.svg'
import { v4 as uuidv4 } from 'uuid'

interface IAddFilesCallback {
  currentActiveIndex: number
}

const AddImagesComponent: React.FC<IAddFilesCallback> = (props: IAddFilesCallback) => {
  // const [imageUrl, setImageUrl] = useState('')
  const [uploadKey, setUploadKey] = useState<number>(0)
  const [imagePostion, setImagePosition] = useState('')
  const {
    uploadImageList,
    setUploadImageList,
    uploadedFileName,
    setUploadedFileName,
    setUploadImagePosition,
    addImageClicked,
    setAddImageClicked,
    ptg,
    setPtg,
    addImageIndex,
    setAddImageIndex,
    setIsUploaded,
    isUploaded,
  } = useContext(DataContext)

  const [messageApi] = message.useMessage()

  const key = 'updatable'
  const onFileUpload = async (info: any) => {
    const fileTypesAllowed = ['png', 'jpeg', 'jpg']
    setUploadedFileName(info.name)
    const oldName = info.name

    // Code for File upload goes here
    if (
      uploadImageList.length == 0 &&
      (info?.size / 1024 / 1024 < 10 || info?.size / 1024 / 1024 === 10) &&
      fileTypesAllowed.includes(info?.name.toString().toLowerCase().split('.').pop())
    ) {
      const newFileName = addUuidToFilename(oldName)
      const updatedFile = updateFileName(info, newFileName)
      setUploadImageList((prevFiles: any) => [...prevFiles, updatedFile])
      simulateProgress()
    }
  }

  const updateFileName = (originalFile: File, newFileName: string): File =>
    new File([originalFile], newFileName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })

  const addUuidToFilename = (filename: string): string => {
    const lastDotIndex = filename.lastIndexOf('.') // Find the last occurrence of '.'

    if (lastDotIndex === -1) {
      throw new Error('Invalid filename: No extension found.')
    }

    const baseName = filename.substring(0, lastDotIndex) // Extract the base name
    const extension = filename.substring(lastDotIndex + 1) // Extract the extension
    const uuid = uuidv4() // Generate a UUID

    return `${baseName}_${uuid}.${extension}`
  }

  const failure = (option: string, message: string) => {
    const closeIcon = (
      <CloseOutlined
        style={{ color: '#fff', marginLeft: '10px' }}
        onClick={() => messageApi.destroy(key)}
      />
    )

    messageApi.open({
      key,
      // type: 'loading',
      content: (
        <span className='message-span'>
          {/* <div className='loader' /> */}
          {option} <span className='message-text'>{message}</span>
          {closeIcon}
        </span>
      ),
      className: 'message',
      duration: 1.5,
    })
  }

  const simulateProgress = () => {
    let tempPtg = 0
    const interval = setInterval(() => {
      tempPtg += 5
      setPtg(tempPtg)

      if (tempPtg > 100) {
        clearInterval(interval)
      }
    }, 100)
  }

  const uploadProps: UploadProps = {
    action: undefined,
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png'
      const isJpg = file.type === 'image/jpeg'
      if (!isPNG && !isJpg) {
        failure(file.name, 'is not a png/jpg file')
      } else {
        if (uploadImageList.length !== 0) {
          failure('Sorry!', 'Only 1 file addition at a time accepted')
        }
      }
      onFileUpload(file)
      return false
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  const savingImage = async () => {
    // Logic for saving uploaded file to context
    // setUploadImageList() // Add local variable used from fileUpload here to save to context
    // showLoader()
    setAddImageClicked(false)
    setAddImageIndex(-1)
    if (uploadImageList.length > 0) {
      setIsUploaded(true)
    }
    setUploadImagePosition(imagePostion)
  }

  const cancelSave = () => {
    setPtg(0)
    setUploadImageList([])

    // Logic for saving uploaded file to context
    setAddImageClicked(false)
    setAddImageIndex(-1)
    setIsUploaded(false)
    setUploadImagePosition('')
    setImagePosition('')
  }

  const saveBtnValidations = () => {
    if (uploadImageList.length !== 0 && imagePostion !== '') {
      return true
    }
    return false
  }
  const onRemoveFile = () => {
    setUploadImageList([])
    setPtg(0)

    // Reset the upload key (or another trigger if needed) to ensure new files are accepted after removal
    setUploadKey((prevKey) => prevKey + 1)
  }
  return (
    <>
      <Typography.Title level={5} className='input_label'>
        Additional Image
      </Typography.Title>
      <div className='additionalImageBoxDefault'>
        {!addImageClicked ? (
          <>
            {!isUploaded ? (
              <>
                <div className='imagePlaceholder'>No image was added</div>
                <Button
                  key={'add-image'}
                  className='add-image-btn'
                  icon={<PlusOutlined />}
                  type='text'
                  iconPosition={'start'}
                  onClick={() => {
                    setAddImageClicked(true)
                    setAddImageIndex(props.currentActiveIndex)
                  }}
                >
                  Add Image
                </Button>
              </>
            ) : (
              <>
                <CheckOutlined className='check-icon' />
                <div className='file-name-placeholder'>{uploadedFileName}</div>
                <Button
                  key={'edit'}
                  className='edit-image-btn'
                  icon={<EditOutlined />}
                  iconPosition={'start'}
                  onClick={() => {
                    setAddImageClicked(true)
                    setAddImageIndex(props.currentActiveIndex)
                  }}
                >
                  Edit
                </Button>
              </>
            )}
          </>
        ) : (
          <div className='image-update-btn'>
            <Button key='cancel' className='cancel-btn' onClick={() => cancelSave()}>
              Cancel
            </Button>
            <Button
              className={saveBtnValidations() ? 'done-button' : 'disabled-done-button'}
              type='primary'
              disabled={!saveBtnValidations()}
              onClick={() => savingImage()}
            >
              Save
            </Button>
          </div>
        )}
      </div>
      {addImageClicked && addImageIndex === props.currentActiveIndex && (
        <div className='image-upload-section'>
          <div className='info-box'>
            <img src={info} className='info-icon' />
            You can add a custom image to your slide by uploading it or providing a link
          </div>
          <div className='upload-custom-image-tag'>
            <div className='upload-image-head'>Upload Custom Image</div>
            <div className='option-tag'>Optional</div>
          </div>
          <div className='upload-Custom-image-dragger'>
            <div>
              <Upload.Dragger
                key={uploadKey}
                action={undefined}
                className='upload-box-dragger-image'
                {...uploadProps}
                accept='image/jpeg, image/png'
                showUploadList={false}
                multiple={false}
                maxCount={1}
              >
                <div className='drag-and-drop-text'>
                  Drag & Drop file(s) here or <span className='browse-button'>Browse</span>
                </div>
              </Upload.Dragger>
            </div>
            <div className='supported-document-text'>Supported formats PNG, JPEG. Up to 10 Mb</div>
            <div className='upload-progress-bars-container'>
              {uploadImageList &&
                uploadImageList.map((fileObj: any, index: number) => (
                  <div key={index}>
                    <div className='progress-bar-section'>
                      <span className='checkIcon'>
                        <img src={check} />
                      </span>
                      {uploadedFileName}
                      <span className='size-progress'>
                        {`${(fileObj?.size / 1024 / 1024).toFixed(2)} MB`}
                        <img src={divider} className='divider-icon' />
                        <img
                          src={cross}
                          className='cross-icon'
                          onClick={async () => {
                            onRemoveFile()
                          }}
                        />
                      </span>
                    </div>
                    <Progress
                      percent={ptg}
                      strokeLinecap='butt'
                      size={['', 4]}
                      showInfo={false}
                      strokeColor={'#007CB0'}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* <div className='upload-custom-image-tag'>
            <div className='upload-image-head'>Paste Image URL</div>
            <div className='option-tag'>Optional</div>
          </div>
          <Input className='url-input' value={imageUrl} defaultValue='' /> */}
          <div className='position-image-head'>Image position</div>
          <div className='position-images'>
            {imagePostion === 'left' ? (
              <div className='postion-left-selected'>
                <div className='position-left-inner-selected' />
              </div>
            ) : (
              <div className='postion-left' onClick={() => setImagePosition('left')}>
                <div className='position-left-inner' />
              </div>
            )}
            {imagePostion === 'right' ? (
              <div className='postion-right-selected'>
                <div className='position-right-inner-selected' />
              </div>
            ) : (
              <div className='postion-right' onClick={() => setImagePosition('right')}>
                <div className='position-right-inner' />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default AddImagesComponent
